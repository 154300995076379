import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import {api} from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["token"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    application: {},
    token: null,
    user: null,
  },

  getters: {
    application: ({application}) => application,
    authenticated: ({token, user}) => !!token && !!user,
    token: ({token}) => token,
    user: ({user}) => user,
  },

  mutations: {
    SET_APPLICATION(state, payload) {
      state.application = payload;
    },

    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    setApplication({commit}, payload) {
      commit("SET_APPLICATION", payload);
    },

    setSignIn({dispatch}, payload) {
      dispatch("setToken", payload.token);
      dispatch("setUser", payload.user);
    },

    setSignOut({dispatch}) {
      dispatch("setToken", null);
      dispatch("setUser", null);
    },

    setToken({commit}, payload) {
      commit("SET_TOKEN", payload);

      // set header authotization
      api.defaults.headers.common["Authorization"] = payload;
    },

    setUser({commit}, payload) {
      commit("SET_USER", payload);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
