import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#ff6700",
        secondary: "#FFB300",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#FFFFFF",
        altback: "#f8f7fa",
        surface: "#FFFFFF",
        accent: "#EFEFF0",
        overtext: "#000000",
        subtext: "#9b9ca2",
      },
      dark: {
        primary: "#ff6700",
        secondary: "#FFB300",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#0e0e0f",
        altback: "#131314",
        surface: "#1a1a1c",
        accent: "#1e1e21",
        overtext: "#FFFFFF",
        subtext: "#9b9ca2",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
