<template>
  <v-row class="py-4 align-center justify-center" no-gutters>
    <v-col cols="5" sm="4" md="3" lg="2" xl="1">
      <v-progress-linear
        color="primary"
        height="6"
        indeterminate
        rounded
      />
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style>
</style>