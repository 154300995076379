<template>
  <v-overlay style="background-color: #000000bf">
    <v-progress-circular :size="64" color="primary" indeterminate />
  </v-overlay>
</template>

<script>
export default {};
</script>

<style>
</style>