import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/auth",
    component: () => import("../views/auth/Auth.vue"),
    children: [
      {
        path: "",
        name: "Sign In",
        meta: {preventAuth: true},
        component: () => import("../views/auth/children/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "Sign Up",
        meta: {preventAuth: true},
        component: () => import("../views/auth/children/SignUp.vue"),
      },
    ],
  },
  {
    path: "/auth2",
    component: () => import("../views/auth/Auth.vue"),
    children: [
      {
        path: "",
        name: "Sign In",
        meta: {preventAuth: false},
        component: () => import("../views/auth/children/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "Sign Up",
        meta: {preventAuth: false},
        component: () => import("../views/auth/children/SignUp.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/e-sports",
    name: "E-Sports",
    component: () => import("../views/esports/Esports.vue"),
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("../views/events/Events.vue"),
  },
  {
    path: "/menu/:category?",
    name: "Menu",
    component: () => import("@/views/menu/Menu.vue"),
  },
  {
    path: "/gallery",
    component: () => import("../views/gallery/Gallery.vue"),
    children: [
      {
        path: "",
        name: "Highlights",
        component: () => import("../views/gallery/children/Highlights.vue"),
      },
      {
        path: "albums",
        name: "Albums",
        component: () => import("../views/gallery/children/Albums.vue"),
      },
      {
        path: "albums/:id?",
        name: "Pictures",
        component: () => import("../views/gallery/children/Pictures.vue"),
      },
    ],
  },
  /* {
    path: "/schedules",
    component: () => import("../views/schedules/Schedules.vue"),
    children: [
      {
        path: "",
        name: "Schedules",
        component: () => import("../views/schedules/children/Schedules.vue"),
      },
      {
        path: "new/:index?",
        name: "New Schedule",
        meta: {requiredAuth: true},
        component: () => import("../views/schedules/children/NewSchedule.vue"),
      }
    ]
  }, */
  {
    path: "/about",
    name: "About",
    component: () => import("../views/about/About.vue"),
  },
  {
    path: "/user",
    component: () => import("../views/user/User.vue"),
    children: [
      {
        path: "",
        name: "My Profile",
        component: () => import("../views/user/children/Profile.vue"),
      },
      {
        path: "schedules",
        name: "My Schedules",
        component: () => import("../views/user/children/Schedules.vue"),
      },
    ],
  },
  {
    path: "/testes",
    name: "Testes",
    component: () => import("../views/testes/Testes.vue"),
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import("../views/notFound/NotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(beforeEach);

export default router;
