<template>
  <div class="d-flex flex-column fill-height">
    <!-- app bar -->
    <v-app-bar
      id="nav"
      class="px-2"
      color="background"
      elevate-on-scroll
      scroll-target
      dense
      app
    >
      <v-img
        :src="
          require(`@/assets/logo-${
            $vuetify.theme.dark ? 'white' : 'black'
          }.png`)
        "
        class="mr-4"
        max-width="120"
      />

      <!-- routes -->
      <v-tabs
        v-show="$vuetify.breakpoint.mdAndUp"
        active-class="overtext--text"
        background-color="transparent"
      >
        <v-tabs-slider color="primary" />

        <v-tab v-for="(item, index) in routes" v-show="!item.disabled" :key="index" :to="item.path">
          {{ item.label }}
        </v-tab>
      </v-tabs>

      <v-spacer />

      <!-- authenticated -->
      <v-sheet
        v-if="$store.getters.authenticated"
        v-ripple
        color="accent"
        class="mr-4 py-1 px-2 rounded-xl pointer-hand"
        flat
        @click="openUser()"
      >
        <div class="d-flex align-center">
          <span class="mr-2">{{ user.firstName }}</span>

          <v-avatar size="26">
            <v-img src="@/assets/utils/profile.png" />
          </v-avatar>
        </div>
      </v-sheet>

      <!-- sign -->
      <v-btn v-else color="gradient" class="mr-4" dark to="/auth">
        Entrar
      </v-btn>

      <!-- theme-->
      <v-icon @click="changeTheme()">
        {{
          $vuetify.theme.dark
            ? "mdi-moon-waning-crescent"
            : "mdi-white-balance-sunny"
        }}
      </v-icon>

      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        class="rounded-lg ml-4"
        small
        icon
        @click="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- mobile drawer -->
    <v-navigation-drawer v-model="drawer" color="surface" right temporary app>
      <v-list class="transparent" nav>
        <v-list-item v-for="(item, i) in routes" v-show="!item.disabled" :key="i" :to="item.path">
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- slot view -->
    <v-main class="background">
      <slot />
    </v-main>

    <v-divider />

    <v-footer color="background" class="px-0">
      <v-container fluid>
        <!-- copyright -->
        <div class="subtext--text text-center">
          © {{ new Date().getFullYear() }} ARENA Jogue Fácil. Todos os direitos
          reservados.
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      routes: [
        { label: "Início", path: "/home", disabled: false },
        { label: "E-Sports", path: "/e-sports", disabled: true },
        { label: "Eventos", path: "/events", disabled: true },
        { label: "Cardápio", path: "/menu", disabled: false },
        { label: "Galeria", path: "/gallery", disabled: true },
        { label: "Agendamentos", path: "/schedules", disabled: false },
        { label: "Sobre", path: "/about", disabled: false },
      ],
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    openUser() {
      this.$router.push({ path: "/user" });
    },

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark-mode", this.$vuetify.theme.dark);
    },
  },
};
</script>

<style>
</style>
