<template>
  <v-img :src="require('@/assets/utils/header.png')" class="rounded-xl" />
</template>

<script>
export default {
  props: {
    file: null,
  },
};
</script>

<style>
</style>