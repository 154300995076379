<template>
  <v-container class="background" fill-height align-center justify-center fluid>
    <v-progress-circular :size="64" color="primary" indeterminate />
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>