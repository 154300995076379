<template>
  <v-main class="background">
    <v-container fill-height fluid>
      <v-row class="justify-center align-center">
        <v-col cols="8" sm="6" md="4" lg="3">
          <v-img
            :src="
              require(`../assets/logo-${
                $vuetify.theme.dark ? 'white' : 'black'
              }.png`)
            "
            class="mb-12"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
</style>
